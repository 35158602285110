import React, { useState, useEffect } from "react";
import { Grid, Paper, Button } from "@mui/material";
import { formatFechaLaravel } from "../../../helpers/functions";
import Table from "../../../components/Table/Table4";
import Table2 from "../../../components/Table/Table8";
import Container from "../../Theme/Loading";
import TransportConditionsModal from "../Components/TransportConditionsModal";
import BillingDataModal from "../Components/BillingDataModal";
import useAxios from "../../../hooks/useAxios";

const UserHomeServiciosDetalleServicio = ({ data, novedades }) => {
  const axios = useAxios();
  const [openTransportModal, setOpenTransportModal] = useState(false);
  const [openBillingModal, setOpenBillingModal] = useState(false);
  const [dataService, setDataService] = useState(null);
  const [json, setJson] = useState(null);
  const handleOpenTransportModal = () => setOpenTransportModal(true);
  const handleCloseTransportModal = () => setOpenTransportModal(false);

  const handleOpenBillingModal = () => setOpenBillingModal(true);
  const handleCloseBillingModal = () => setOpenBillingModal(false);

  const handleOpenWhatsAppChat = () => {
    // Número de teléfono al que se enviará el mensaje
    const phoneNumber = process.env.REACT_APP_WHATSAPP_PHONE_NUMBER;
    console.log("Numero de cel", phoneNumber);
    // Abre el chat de WhatsApp en una nueva pestaña
    window.open(`https://wa.me/${phoneNumber}`, "_blank");
  };
  const formatCurrency = (value) => {
    return `$${value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}`;
  };
  useEffect(() => {
    console.log("Data ojo:", data.json);
    try {
      const parsedJson =
        typeof data.json === "string" ? JSON.parse(data.json) : data.json;
      setJson(parsedJson);
    } catch (e) {
      console.error("Error parsing JSON:", e);
      setJson(null);
    }

    axios.getData({}).then((response) => {
      if (data && data.id) {
        const BACKEND = "api/" + process.env.REACT_APP_BACKEND_VERSION;

        axios.getData({}, `${BACKEND}/dashboard/servicios/detail/${data.id}`).then((response) => {
          if (response && response.data) {
            setDataService(response.data);
          }
        });
      }
    });

  }, [data]);

  if (!json) {
    return <div></div>;
  }
  return (
    <Container>
      <Grid container justifyContent={"center"} spacing={1}>
        <Grid item xs={10} md={4}>
          <Paper sx={{ p: 1 }}>
            <Grid component={"div"}>
              <b>Código</b>
            </Grid>
            <Grid component={"label"}>#{data.id}</Grid>
          </Paper>
        </Grid>
        <Grid item xs={10} md={4}>
          <Paper sx={{ p: 1 }}>
            <Grid component={"div"}>
              <b>Fecha</b>
            </Grid>
            <Grid component={"label"}>
              {formatFechaLaravel(data.created_at)}
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={10} md={4}>
          <Paper sx={{ p: 1 }}>
            <Grid component={"div"}>
              <b>Estado Servicio</b>
            </Grid>
            <Grid component={"label"}>{data.status}</Grid>
          </Paper>
        </Grid>
        {/*
                            <Grid item xs={10} md={3}>
                                <Paper sx={{p:1}}>
                                    <Grid component={"div"}><b>Modalidad</b></Grid>
                                    <Grid component={"label"}>
                                        {data.modalidad}
                                    </Grid>
                                </Paper>
                            </Grid>    
                        * */}
        <Grid item xs={10} md={4}>
          <Paper sx={{ p: 1 }}>
            <Grid component={"div"}>
              <b>Tipo de Carga</b>
            </Grid>
            <Grid component={"label"}>{data.tipo_carga}</Grid>
          </Paper>
        </Grid>
        {/* <Grid item xs={10} md={4}>
          <Paper sx={{ p: 1 }}>
            <Grid component={"div"}>
              <b>Tipo de Servicio</b>
            </Grid>
            <Grid component={"label"}>{data.tipo_servicio}</Grid>
          </Paper>
        </Grid> */}
        <Grid item xs={10} md={4}>
          <Paper sx={{ p: 1 }}>
            <Grid component={"div"}>
              <b>Valor declarado</b>
            </Grid>
            <Grid component={"label"}>
              {formatCurrency(json.merchandise_value)}
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={10} md={4}>
          <Paper sx={{ p: 1 }}>
            <Grid component={"div"}>
              <b>Peso de la mercancía</b>
            </Grid>
            <Grid component={"label"}>{json.merchandise_weight}kg</Grid>
          </Paper>
        </Grid>
        <Grid item xs={10} md={4}>
          <Paper sx={{ p: 1 }}>
            <Grid component={"div"}>
              <b>Descripción de la mercancía</b>
            </Grid>
            <Grid component={"label"}>{json.merchandise_descripction}</Grid>
          </Paper>
        </Grid>
        <Grid item xs={10} md={4}>
          <Paper sx={{ p: 1 }}>
            <Grid component={"div"}>
              <b>Condiciones del transporte</b>
            </Grid>
            <Grid component={"label"}>
              {json.merchandise_especial_descripction}
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={10} md={4}>
          <Paper sx={{ p: 1 }}>
            <Grid component={"div"}>
              <b>Tipo de Vehículo</b>
            </Grid>
            <Grid component={"label"}>{data.tipo_vehiculo}</Grid>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          {data && data.direcciones && <Table data={data.direcciones} />}
        </Grid>
        <Grid item xs={12}>
          {data && dataService && <Table2 data={data} novedades={novedades} dataService={dataService} />}
        </Grid>
        {/* Botones */}
        <Grid
          container
          spacing={2}
          sx={{ m: 0.25, justifyContent: "center", alignItems: "center" }}
        >
          <Grid item xs={4}>
            <Button
              fullWidth
              variant="contained"
              type="submit"
              onClick={handleOpenTransportModal}
              sx={{
                px: 4,
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              Condiciones de transporte
            </Button>
          </Grid>
          <Grid item xs={4}>
            <Button
              fullWidth
              variant="contained"
              type="submit"
              onClick={handleOpenBillingModal}
              sx={{
                px: 4,
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              Datos de facturación
            </Button>
          </Grid>
          <Grid item xs={4}>
            <Button
              fullWidth
              variant="contained"
              onClick={handleOpenWhatsAppChat}
              sx={{
                px: 4,
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                backgroundColor: "#52BDC7",
                "&:hover": {
                  backgroundColor: "#4699A6",
                },
              }}
            >
              Chat de servicio
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <TransportConditionsModal
        open={openTransportModal}
        handleClose={handleCloseTransportModal}
      />
      <BillingDataModal
        open={openBillingModal}
        handleClose={handleCloseBillingModal}
        data={data}
      />
    </Container>
  );
};

export default UserHomeServiciosDetalleServicio;

import { Grid, Typography, Box } from "@mui/material";
import * as React from "react";

export default function ControlledOpenSelect({
  items,
  label,
  name,
  value,
  onChange,
}) {
  console.log("ITEMS", items);
  console.log("VALUE", value);

  const handleOnChange = (e) => {
    console.log("Changed 2 value:", e.target.value);
    onChange(e.target.value); // Asegúrate de que el valor sea el esperado
  };

  return (
    <Grid sx={{ position: "relative" }} className="select2023">
      <Box>
        <Typography className="label">{label}</Typography>
      </Box>
      <select
        className="select2023"
        value={value} // Esto asegura que el valor seleccionado se refleje correctamente
        name={name}
        onChange={handleOnChange}
        required
      >
        <option value="">Seleccione</option>
        {Array.isArray(items) &&
          items.map((row, key) => {
            const optionValue = row.value || row.id; // Usar id o value como valor único
            return (
              <option value={optionValue} key={optionValue}>
                {row.label}
              </option>
            );
          })}
      </select>
    </Grid>
  );
}

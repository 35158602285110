import React, { useEffect, useState, useContext } from "react";
import PropTypes from "prop-types";
import { useLocation, NavLink } from "react-router-dom";
import StateContext from "../../helpers/ContextState";
import {
  Table as MuiTable,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  IconButton,
} from "@mui/material";
import "./Table.css"; // Importa el archivo CSS para estilos personalizados
import useAxios from "../../hooks/useAxios";
import Pagination from "@mui/material/Pagination";
import { useNavigate } from "react-router-dom";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import EditIcon from "@mui/icons-material/Edit";
//import DeleteIcon from '@mui/icons-material/Delete';
import CommentIcon from "@mui/icons-material/Comment";
import SearchIcon from "@mui/icons-material/Search";
import ChecklistRtlIcon from "@mui/icons-material/ChecklistRtl";

import Search from "../Input/Search";
import Dialog from "../Dialog";
import FullScreenModal from "../FullScreenModal";
import Comentarios from "../Comentarios";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { Link } from "react-router-dom";

let getInit = null;
let filter = "";
const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
let current = parseInt(urlParams.get("page"))
  ? parseInt(urlParams.get("page"))
  : 1;

const BACKEND =
  window.location.hostname === "localhost"
    ? process.env.REACT_APP_BACKEND
    : process.env.REACT_APP_BACKENDREMOTE;

const dataFilters = {
  userStatus: {
    options: [
      { label: "Pendiente", value: "pending" },
      { label: "Aceptado", value: "accept" },
      { label: "Rechazado", value: "reject" },
    ],
  },
  serviceStatus: {
    options: [
      { label: "Solicitado", value: 22 },
      { label: "Enviado", value: 23 },
      { label: "Entregado", value: 24 },
      { label: "Facturado", value: 25 },
      { label: "Pagado", value: 26 },
      { label: "Anulado", value: 27 },
      { label: "Sin Ruta Disponible", value: 28 },
    ],
  },
  enterpriseStatus: {
    endpoint:
      "api/" + process.env.REACT_APP_BACKEND_VERSION + "/dashboard/clientes",
    options: [],
  },
};

const Table = ({
  title,
  filters,
  td = [],
  endpoint,
  permissions,
  create,
  subFixed,
  skipSearch,
  download,
  upload,
  customAdd,
  href,
  skipAdd,
  fullScreenModal,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const context = useContext(StateContext);
  const axios = useAxios();
  const [selectEnterprise, setSelectEnterprise] = useState([]);
  const [status, setStatus] = useState();
  const [enterprise, setEnterprise] = useState();
  // Extrae los nombres de los campos del primer elemento del array de datos
  const [totalPages, setTotalPages] = useState(0);
  const [rows, setRows] = useState([]);
  const [open, setOpen] = useState(false);
  const [endpointState, setEndpointState] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [imported, setImported] = useState(false);
  const [_id, set_id] = useState(0);
  const [filter, setFilter] = useState("");
  const [currentPage, setCurrentPage] = useState(current);

  const getInit = (mounted, new_url_set) => {
    if (!mounted) {
      return false;
    }

    context.setLoadingTable(true);
    if (filter || filter === "") {
      setTotalPages(0);
    }

    let endpoint_ =
      "api/" +
      process.env.REACT_APP_BACKEND_VERSION +
      location.pathname +
      location.search;

    let ext = "";

    if (filter === undefined) {
      filter = "";
    }

    if (subFixed) {
      ext = subFixed;
    }

    if (endpoint) {
      endpoint_ = endpoint;
    }

    setEndpointState(endpoint_ + ext);

    //eventos/evento-1
    const str = endpoint_;
    const regex = /evento-(\d+)/;
    const match = str.match(regex);
    if (!_id && match && match[1]) {
      set_id(parseInt(match[1]));
    }

    const statusParam = status ? { status } : {};
    const searchParam = filter !== "" ? { search: filter } : {};
    const enterpriseParam = enterprise ? { enterprise } : {};

    axios
      .getData(
        {
          ...statusParam,
          ...searchParam,
          ...enterpriseParam,
        },
        new_url_set || endpoint_ + ext
      )
      .then((response) => {
        if (
          response &&
          response.status &&
          response.status === 401 &&
          response.statusText
        ) {
          context.setOpenMessage({
            message: "Tu sesión ha caducado...",
            severity: "error",
          });
          context.setUser(false);
          navigate("/dashboard");
        }

        if (
          response &&
          (response.status === 500 || response.code === "ERROR")
        ) {
          context.setHide(500);
          let message = response.statusText;

          if (response.data && response.data.message) {
            message = response.data.message;
          }
          context.setOpenMessage({
            message: "Error 500 " + message,
            severity: "error",
          });
        }

        if (response && response.status === 404) {
          context.setHide(404);
          context.setOpenMessage({
            message:
              "Presentamos problemas para comunicarnos con la nube, por favor intenta en unos minutos...",
            severity: "error",
          });
        }

        if (
          response &&
          response.data &&
          response.data.exception &&
          response.data.message
        ) {
          context.setHide(403);
          context.setOpenMessage({
            message: "Error 403 " + response.data.message,
            severity: "error",
          });
        }

        if (response && response.data && response.data.data) {
          setRows(response.data.data);
        }
        if (response && response.data && response.data.links) {
          const calcular = Math.ceil(
            response.data.total / parseInt(response.data.per_page)
          );
          setTotalPages(calcular);
        }

        context.setLoadingTable(false);
      });

    if (filters?.enterpriseStatus) {
      axios
        .getData(
          { format: "json", status: "accept" },
          dataFilters.enterpriseStatus.endpoint
        )
        .then((response) => {
          if (response && response.data) {
            setSelectEnterprise(response.data);
          }
        });
    }
  };

  useEffect(() => {
    setEndpointState(false);
    let mounted = true;
    getInit(mounted);
    return function cleanup() {
      mounted = false;
    };
  }, [status, enterprise, imported, filter]);

  // Verifica si no se proporcionaron datos válidos
  if (!td || td.length === 0) {
    return (
      <Grid align="center">
        No se pueden mostrar los datos. Los campos son incompletos.
      </Grid>
    );
  }

  const onChange = (event, page) => {
    //console.log(subFixed);
    let new_url_set;
    if (!subFixed) {
      location.search = "?page=" + page;
      navigate(location.pathname + "?page=" + page);
    } else {
      new_url_set = endpointState + "&page=" + page;
    }
    getInit(true, new_url_set);
  };

  const onChangeSearch = (event) => {
    navigate(location.pathname);
    setFilter(event.target.value);
    setCurrentPage(1); // Establecer la página actual a 1
  };

  const handleEventSearch = (bool) => {
    console.log("line 215 bool", bool);
    if (!bool) {
      setFilter("");
    }
    getInit(true);
  };

  const handleEvent = (action) => {
    const {
      transportation_value,
      loading_value,
      unloading_value,
      max_global_commission_percentage,
      ...filteredAction
    } = action;

    setOpen(filteredAction);
  };

  const handleEventAdd = (action) => {
    const new_item = {
      label: "Agregar",
      action: "create",
      id: 0,
      ma_estatus_id: 8,
      user_id: 0,
      name: "",
      website: "",
      email: "",
      celular: "",
      component: create,
      rif: "",
      direccion: "",
      avatar: "",
      created_at: "",
      updated_at: "",
      fecha: "",
    };
    setOpen(new_item);
  };

  const handleFilterEnterprise = (enterprise_id) => {
    setEnterprise(enterprise_id);
  };

  const handleFilterState = (state_id) => {
    setStatus(state_id);
  };

  function downloadExcel() {
    window.location.href =
      BACKEND + "excel/plantilla_cargue_configuracion_global_trucker.xlsx";
  }

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    axios
      .postDataAttachment(
        { file: file },
        "api/" +
          process.env.REACT_APP_BACKEND_VERSION +
          "/dashboard/configuracion/import"
      )
      .then((response) => {
        context.setOpenMessage({
          message: `Creados: ${response?.created.length ?? 0} | Actualizados: ${
            response?.updated.length ?? 0
          } | Errores: ${response?.errors.length ?? 0} `,
          severity: response?.errors.length ? "error" : "success",
        });
      })
      .finally(() => {
        event.target.value = null;
        setImported(!imported);
        setSelectedFile(null);
      });
  };

  //console.log(permissions.find(search=>search==="create"));

  return (
    <>
      {!fullScreenModal && (
        <Dialog open={open} setOpen={setOpen} getInit={getInit} />
      )}
      {fullScreenModal && (
        <FullScreenModal open={open} setOpen={setOpen} getInit={getInit} />
      )}
      {skipSearch !== "all" && (
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          marginBottom={3}
        >
          <Grid sx={{ fontSize: 16 }}>
            <h2 style={{ margin: 0 }}>{title ? title : ""}</h2>
          </Grid>
          <Grid className="search" display="flex" alignItems="end" gap="1rem">
            <Grid style={{ width: 150 }}>
              {(filters?.userStatus || filters?.serviceStatus) && (
                <FormControl fullWidth>
                  <InputLabel id="select-state-label">Estado</InputLabel>
                  <Select
                    id="select-state"
                    className="select"
                    label="Estado"
                    size="small"
                    variant="filled"
                    style={{
                      height: 44,
                      background: "white",
                      borderTopLeftRadius: 4,
                      borderTopRightRadius: 4,
                    }}
                    onChange={(event) => handleFilterState(event.target.value)}
                  >
                    {(filters?.userStatus
                      ? dataFilters?.userStatus?.options
                      : filters?.serviceStatus
                      ? dataFilters?.serviceStatus?.options
                      : []
                    )?.map((option) => (
                      <MenuItem key={option.label} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
              {filters?.enterpriseStatus && (
                <FormControl fullWidth>
                  <InputLabel id="select-state-label">Empresa</InputLabel>
                  <Select
                    id="select-state"
                    className="select"
                    label="Empresa"
                    size="small"
                    variant="filled"
                    disabled={selectEnterprise.length === 0}
                    style={{
                      height: 44,
                      background: "white",
                      borderTopLeftRadius: 4,
                      borderTopRightRadius: 4,
                    }}
                    onChange={(event) =>
                      handleFilterEnterprise(event.target.value)
                    }
                  >
                    {selectEnterprise?.map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            </Grid>
            <Grid sx={{ display: "flex", gap: 2, width: 488 }}>
              <Search
                getInit={getInit}
                customAdd={customAdd ? endpointState : false}
                href={href || false}
                upload={upload ? endpointState : false}
                download={download ? endpointState : false}
                skipSearch={skipSearch}
                add={
                  permissions.find((search) => search === "create") &&
                  !customAdd &&
                  !skipAdd
                    ? handleEventAdd
                    : false
                }
                handleEventSearch={handleEventSearch}
                fullWidth
                label="Buscar..."
                name="search"
                onChange={onChangeSearch}
              >
                {location.pathname === "/dashboard/configuracion" && (
                  <>
                    <IconButton
                      color="success"
                      sx={{ p: "10px" }}
                      aria-label="Descargar plantilla excel"
                      onClick={() => downloadExcel()}
                    >
                      <FileCopyIcon />
                    </IconButton>
                    <IconButton
                      color="success"
                      sx={{ p: "10px" }}
                      aria-label="Subir registros excel"
                    >
                      <UploadFileIcon />
                      <input
                        type="file"
                        title={
                          selectedFile
                            ? selectedFile.name
                            : "No ha seleccionado ningún archivo"
                        }
                        accept=".xlsx,.xls"
                        style={{
                          position: "absolute",
                          width: 44,
                          height: 44,
                          opacity: 0,
                          cursor: "pointer",
                        }}
                        onChange={(e) => {
                          handleFileChange(e);
                          console.log("aaa");
                        }}
                      />
                    </IconButton>
                  </>
                )}
              </Search>
            </Grid>
          </Grid>
        </Grid>
      )}

      <MuiTable size="small">
        <TableHead>
          <TableRow>
            {td.map((column, index) => (
              <TableCell
                align={column.align || "right"}
                key={index}
                className={index === td.length - 1 ? "" : ""}
                width={index === td.length - 1 ? "120px" : ""}
              >
                {/*VERIFICO SI TENGO PERMISOS DE VER CADA PERFIL*/}
                {((column.label === "Acción" &&
                  permissions.find((search) => search === "show")) ||
                  column.label !== "Acción") && (
                  <Grid align={index === td.length - 1 ? "center" : td.align}>
                    {column.label}
                  </Grid>
                )}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.length > 0 && context.loadingTable === false ? (
            rows.map((item, index) => (
              <TableRow key={index}>
                {td.map((column, columnIndex) => {
                  if (
                    !permissions.find(
                      (search) => search === "show" || search === "edit"
                    )
                  ) {
                    return false;
                  }

                  /*VERIFICO SI TENGO PERMISOS DE VER CADA PERFIL*/

                  return (
                    <TableCell
                      key={columnIndex}
                      align={column.align}
                      className={
                        columnIndex === td.length - 1 ? "last-column" : ""
                      }
                    >
                      {column.field && column.field !== "url"
                        ? item[column.field]
                        : null}

                      {column.field && column.field === "url" ? (
                        <>
                          <a
                            rel="noreferrer"
                            target="_blank"
                            href={item[column.field]}
                          >
                            <SearchIcon />
                          </a>
                        </>
                      ) : (
                        <>
                          {column.event && column.event.length > 0 ? (
                            <Grid
                              sx={{ display: "flex" }}
                              justifyContent="center"
                            >
                              {column.event.map((event, eventIndex) => {
                                if (event.component) {
                                  if (
                                    permissions.find(
                                      (search) => search === "show"
                                    ) &&
                                    event.label === "Ver"
                                  ) {
                                    return (
                                      <Grid
                                        variant="link"
                                        title={event.label}
                                        key={eventIndex}
                                        onClick={() =>
                                          handleEvent({ ...event, ...item })
                                        }
                                      >
                                        <SearchIcon
                                          className="cursor-pointer"
                                          title={event.label}
                                        />
                                      </Grid>
                                    );
                                  }
                                  if (
                                    permissions.find(
                                      (search) => search === "show"
                                    ) &&
                                    event.label === "Inmuebles"
                                  ) {
                                    return (
                                      <Grid
                                        variant="link"
                                        title={event.label}
                                        key={eventIndex}
                                        onClick={() =>
                                          handleEvent({ ...event, ...item })
                                        }
                                      >
                                        <ChecklistRtlIcon
                                          className="cursor-pointer"
                                          title={event.label}
                                        />
                                      </Grid>
                                    );
                                  }
                                  if (
                                    permissions.find(
                                      (search) => search === "edit"
                                    ) &&
                                    event.label === "Editar"
                                  ) {
                                    return (
                                      <Grid
                                        variant="link"
                                        title={event.label}
                                        key={eventIndex}
                                        onClick={() =>
                                          handleEvent({ ...event, ...item })
                                        }
                                      >
                                        <EditIcon
                                          className="cursor-pointer"
                                          title={event.label}
                                        />
                                      </Grid>
                                    );
                                  }

                                  if (event.label === "Comentarios") {
                                    return (
                                      <Grid
                                        variant="link"
                                        title={event.label}
                                        key={eventIndex}
                                        onClick={() =>
                                          handleEvent({
                                            ...event,
                                            component: Comentarios,
                                            ...item,
                                          })
                                        }
                                      >
                                        <CommentIcon
                                          className="cursor-pointer"
                                          title={event.label}
                                        />
                                      </Grid>
                                    );
                                  }

                                  if (event.label === "PDF") {
                                    return (
                                      <Link
                                        variant="link"
                                        title={event.label}
                                        key={eventIndex}
                                        to={
                                          BACKEND +
                                          "api/PDF?colletion=" +
                                          item.token +
                                          "&e=" +
                                          _id
                                        }
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        <PictureAsPdfIcon
                                          className="cursor-pointer"
                                          title={event.label}
                                        />
                                      </Link>
                                    );
                                  }
                                }

                                if (event.byIndex && item[event.byIndex.key]) {
                                  if (
                                    permissions.find(
                                      (search) => search === "show"
                                    ) &&
                                    event.label === "Ver"
                                  ) {
                                    return (
                                      <NavLink
                                        variant="link"
                                        to={
                                          (event.byIndex &&
                                          event.byIndex.prefixed
                                            ? event.byIndex.prefixed + "-"
                                            : "/") + item[event.byIndex.key]
                                        }
                                        key={eventIndex}
                                      >
                                        {event.label === "Ver" && (
                                          <SearchIcon
                                            className="cursor-pointer"
                                            title={event.label}
                                          />
                                        )}
                                      </NavLink>
                                    );
                                  }
                                  if (
                                    permissions.find(
                                      (search) => search === "edit"
                                    ) &&
                                    event.label === "Editar"
                                  ) {
                                    return (
                                      <NavLink
                                        variant="link"
                                        to={
                                          (event.byIndex &&
                                          event.byIndex.prefixed
                                            ? event.byIndex.prefixed + "-"
                                            : "/") + item[event.byIndex.key]
                                        }
                                        key={eventIndex}
                                      >
                                        {event.label === "Editar" && (
                                          <EditIcon
                                            className="cursor-pointer"
                                            title={event.label}
                                          />
                                        )}
                                      </NavLink>
                                    );
                                  }
                                }

                                return false;
                              })}
                            </Grid>
                          ) : null}
                        </>
                      )}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={td.length}>
                <Grid align="center">
                  {context.loading
                    ? "Consultando información..."
                    : "No hay resultados que mostrar."}
                </Grid>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </MuiTable>
      <Grid container direction="row">
        <Grid item xs={12} sx={{ mt: 1, mb: 1, p: 0 }}>
          <Pagination
            page={currentPage} // Reemplazar defaultPage por page
            onChange={(event, page) => {
              setCurrentPage(page);
              onChange(event, page);
            }}
            count={totalPages}
            shape="rounded"
            color="secondary"
            siblingCount={1}
            boundaryCount={1}
          />
        </Grid>
      </Grid>
    </>
  );
};

Table.propTypes = {
  td: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      field: PropTypes.string,
      className: PropTypes.string,
      align: PropTypes.oneOf(["left", "center", "right"]),
      event: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string.isRequired,
          action: PropTypes.string.isRequired,
        })
      ),
      to: PropTypes.string,
    })
  ).isRequired,
};

export default Table;

import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import useAxios from '../../../hooks/useAxios';
import React, {useEffect, useState} from 'react';
import Select from '../../../components/Select';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import transportadores from "../../../app/Dashboard/Transportadores";
import {formatCurrency} from "../../../helpers/functions";
import {Star} from "@mui/icons-material";

const Basic = ({ data, trasportadores, getInit }) => {
    const axios = useAxios();
    const [inputs, setInputs] = useState({});
    const [transportador, setTranportador] = useState({});
    const storedData = localStorage.getItem('master');
    const [error, setError] = React.useState("");

    const [transporters, setTransporters] = React.useState([]);
    const [selectedTransporter, setSelectedTransporter] = React.useState(null);
    const [selectedOfferId, setSelectedOfferId] = React.useState(null);

    const onSubmit = (e) => {
        e.preventDefault();

        if (!selectedTransporter) {
            return;
        }

        // Guardar los inputs:
        axios.putData(inputs, 'api/v1/dashboard/services').then((response) => {
            if (response && response.data && response.data.id) {
                getInit(true);
            }
        });

        const offerId = selectedTransporter.id;
        const BACKEND = "api/" + process.env.REACT_APP_BACKEND_VERSION;

        setSelectedOfferId(offerId);
        setTransporters((prevTransporters) =>
            prevTransporters.map((t) =>
                t.id === offerId
                    ? {...t, selected_offer: true}
                    : {...t, selected_offer: false}
            )
        );

        axios.postData({}, `${BACKEND}/offers/select/${offerId}`)
            .then((response) => {

                if (response?.status === 200) {
                    setSelectedOfferId(response.data.id);
                }
            })
            .catch((error) => {
                console.error("Error al seleccionar transportador:", error);
            });
    };

  React.useEffect(() => {
    if (data) {
      setInputs(data);
    }
  }, [data, setInputs]);

    React.useEffect(() => {
        if (trasportadores) {
            setTranportador(trasportadores.find(search => search.value === parseInt(inputs.transportador_id)));
        }
    }, [inputs.transportador_id, trasportadores]);
    React.useEffect(() => {
        if (trasportadores) {
            setTranportador(trasportadores.find(search => search.value === parseInt(inputs.transportador_id)));
        }
    }, [inputs.transportador_id, trasportadores]);

    const getDataOffers = () => {
        axios.getData({}).then((response) => {
            if (response && response.data) {
                const BACKEND = "api/" + process.env.REACT_APP_BACKEND_VERSION;

                axios.getData({}, `${BACKEND}/offers?service_id=${data.id}`).then((response) => {
                    if (response && response.data) {
                        setTransporters(response.data);

                        // Buscar oferta seleccionada y asignarla al estado
                        const selectedOffer = response.data.find(transportador => transportador.selected_offer);

                        if (selectedOffer) {
                            setSelectedTransporter(selectedOffer);
                            setSelectedOfferId(selectedOffer.id);
                        }
                    }
                });
            }
        });
    };

    useEffect(() => {
        getDataOffers();
    }, []);

    const getVehicleLabel =  (id) => {
        try {
            const master = JSON.parse(storedData);

            function getVehicles(master) {
                return master.vehiculo.map(vehiculo => ({
                    label: vehiculo.label,
                    value: vehiculo.value,
                    images: vehiculo.images
                }));
            }
  
            const vehicles = getVehicles(master);
  
            const vehicle = vehicles.find((item) => item.value === Number(id));
           
  
            return vehicle ? vehicle.label : '';
        } catch (err) {
            console.error('Error al obtener datos de almacenamiento', err);
            setError(err);
            console.log('Error al obtener datos de almacenamiento', error);
            return '';
        }
    };

    const hasSelectedOffer = transporters.some(transportador => transportador.selected_offer);

    return (
        <form onSubmit={onSubmit}>
            <Grid container spacing={3}>
                <Grid item xs={12} md={3}>
                    {/*
                        <Select
                            value={inputs.transportador_id}
                            items={trasportadores || []}
                            required
                            onChange={handleSelectChange} // Solo pasa el valor
                            size="small"
                            fullWidth
                            name="transportador_id"
                            type="text"
                            label="Nombre"
                        />
                    */}

                    {/* Transportadores (columna izquierda) */}
                    <Grid container spacing={2} sx={{ mt: 1 }}>
                        <Grid item xs={12}>
                            <Typography variant="h6">Transportadores</Typography>
                        </Grid>

                        {Array.isArray(transporters) && transporters.length > 0 ? (
                            transporters.map((transportador, index) => {
                                const {
                                    user: {id, name = "Sin nombre"} = {},
                                    offer: {offer_value = 0} = {},
                                    stats = 0,
                                    selected_offer = false
                                } = transportador;

                                return (
                                    <Grid
                                        key={id || index}
                                        item
                                        xs={12}
                                        container
                                        alignItems="center"
                                        spacing={2}
                                        sx={{py: 2, borderBottom: "1px solid #e0e0e0"}}
                                    >
                                        <Grid item xs="auto">
                                            <img
                                                src="https://placehold.co/45x45"
                                                alt="Avatar"
                                                loading="lazy"
                                                className="avatar-img-driver"
                                            />
                                        </Grid>

                                        <Grid item xs>
                                            <b>{name}</b>
                                            <Typography variant="body2" color="text.secondary">
                                                {formatCurrency(offer_value)}
                                            </Typography>
                                        </Grid>

                                        <Grid item xs="auto">
                                            {!hasSelectedOffer || selected_offer ? (
                                                <Button
                                                    variant="contained"
                                                    sx={{
                                                        backgroundColor: selected_offer ? "#34c759" : "#e8501e",
                                                        color: "#fff",
                                                        borderRadius: "20px",
                                                        "&:hover": {
                                                            backgroundColor: selected_offer ? "#34c759" : "#cf4219"
                                                        },
                                                        width: "120px",
                                                    }}
                                                    onClick={!selected_offer ? () => setSelectedTransporter(transportador) : undefined}
                                                    //disabled={selected_offer}
                                                >
                                                    {selected_offer ? "Seleccionado" : "Seleccionar"}
                                                </Button>
                                            ) : null}
                                        </Grid>

                                    </Grid>
                                );
                            })
                        ) : (
                            <Grid item xs={12}>
                                <Typography variant="body1" align="center" sx={{py: 4}}>
                                    No hay transportadores disponibles.
                                </Typography>
                            </Grid>
                        )}
                    </Grid>


                </Grid>
                <Grid item xs={12} md={9}>
                    {selectedTransporter && (
                        <Card elevation={3}>
                            <CardContent>
                                <Grid container spacing={2}>
                                    {/* User Data Column */}
                                    <Grid item xs={12} md={4}>
                                        <Typography variant="h6" gutterBottom>Datos del Transportador</Typography>
                                        <Divider sx={{ mb: 2 }} />
                                        <Grid container spacing={1}>
                                            <Grid item xs={12}>
                                                <Typography variant="subtitle2">Nombre Transportador</Typography>
                                                <Typography variant="body2">{selectedTransporter ? selectedTransporter.user.name : ""}</Typography>
                                            </Grid>

                                            <Grid item xs={12}>
                                                <Typography variant="subtitle2">Documento</Typography>
                                                <Typography variant="body2">{selectedTransporter?.user.documento_representante_legal || ""}</Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography variant="subtitle2">Correo Electrónico</Typography>
                                                <Typography variant="body2">{selectedTransporter?.user.email || ""}</Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography variant="subtitle2">Número de Teléfono</Typography>
                                                <Typography variant="body2">
                                                    {selectedTransporter?.user.celular && selectedTransporter?.user.celular.trim().substring(0, 2) === '57'
                                                        ? selectedTransporter?.user.celular.trim().substring(2)
                                                        : selectedTransporter?.user.celular}
                                                </Typography>

                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    {/* Vehicle Data Column */}
                                    <Grid item xs={12} md={4}>
                                        <Typography variant="h6" gutterBottom>Datos del Vehículo</Typography>
                                        <Divider sx={{ mb: 2 }} />
                                        <Grid container spacing={1}>
                                            <Grid item xs={12}>
                                                <Typography variant="subtitle2">Marca</Typography>
                                                <Typography variant="body2">{selectedTransporter?.service?.transportador?.vehiculo_marca || ""}</Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography variant="subtitle2">Modelo</Typography>
                                                <Typography variant="body2">{selectedTransporter?.service?.transportador?.vehiculo_modelo || ""}</Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography variant="subtitle2">Placa</Typography>
                                                <Typography variant="body2">{selectedTransporter?.service?.transportador?.vehiculo_placa || ""}</Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography variant="subtitle2">Seguro Vencimiento</Typography>
                                                <Typography variant="body2">
                                                    {selectedTransporter?.service?.transportador?.vehiculo_seguro_vencimiento}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography variant="subtitle2">Tecnomecánica</Typography>
                                                <Typography variant="body2">
                                                    {selectedTransporter?.service?.transportador?.vehiculo_tecnomecanica}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography variant="subtitle2">Tecnomecánica Vencimiento</Typography>
                                                <Typography variant="body2">
                                                    {selectedTransporter?.service?.transportador?.vehiculo_tecnomecanica_vencimiento}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography variant="subtitle2">Tipo de Vehículo</Typography>
                                                <Typography variant="body2">
                                                    {getVehicleLabel(selectedTransporter?.service?.vehiculo?.id)}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    {/* Offer values */}
                                    <Grid item xs={12} md={4}>
                                        <Typography variant="h6" gutterBottom>Datos de la oferta</Typography>
                                        <Divider sx={{ mb: 2 }} />
                                        <Grid container spacing={1}>
                                            <Grid item xs={12}>
                                                <Typography variant="subtitle2">Valor Transporte</Typography>
                                                <Typography variant="body2">{selectedTransporter ? formatCurrency(selectedTransporter.offer.data.transportation_value) : ""}</Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography variant="subtitle2">Valor Global Trucker</Typography>
                                                <Typography variant="body2">{selectedTransporter ? formatCurrency(selectedTransporter.offer.data.global_trucker_value) : ""}</Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography variant="subtitle2">Valor Aseguramiento</Typography>
                                                <Typography variant="body2">{selectedTransporter ? formatCurrency(selectedTransporter.offer.data.insurance_value) : ""}</Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography variant="subtitle2">Valor total</Typography>
                                                <Typography variant="body2">
                                                    {selectedTransporter ? formatCurrency(selectedTransporter.offer.data.total_value) : ""}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    )}
                </Grid>
            </Grid>
            {!selectedOfferId && (
                <Grid container justifyContent="flex-end">
                    <Grid item xs={12} md={6} align="right" sx={{ mt: 2 }}>
                        <Button variant="contained" color="primary" type="submit" fullWidth>
                            Guardar
                        </Button>
                    </Grid>
                </Grid>
            )}
        </form>
    );
};

export default Basic;

import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Documents from "../Uploads/Documents";
import DocumentsBancarios from "../Uploads/DocumentsBancarios";
import TransportadorBasic from "./Components/TransportadorBasic";
import TransportadorVehiculo from "./Components/TransportadorVehiculo";
import TransportadorWallet from "./Components/TransportadorWallet";
import { Grid } from "@mui/material";
import Table2 from "../../components/Table/Table5";
import Table3 from "../../components/Table/Table7";
import Table4 from "../../components/Table/Table10";
import Table5 from "../../components/Table/Table11";
import Button from "@mui/material/Button";
import SelectStatus from "../../components/Button/Select";
import Alert from "@mui/material/Alert";
import useAxios from "../../hooks/useAxios";
import { Select, MenuItem, TextField } from "@mui/material";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box
          sx={{ p: 1, height: 400, overflow: "hidden", overflowY: "scroll" }}
        >
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs({ data }) {
  const axios = useAxios();
  const [documents, setDocuments] = React.useState([]);
  const [value, setValue] = React.useState(0);
  const [backgroundCheckData, setBackgroundCheckData] = React.useState(null);
  const [backgroundPlateCheckData, setBackgroundPlateCheckData] =
    React.useState(null);
  const [ownerDocumentType, setOwnerDocumentType] =
    React.useState("national_id");
  const [ownerDocumentId, setOwnerDocumentId] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const [showAlert, setShowAlert] = React.useState(false);

  const handleOwnerDocumentIdChange = (event) => {
    const value = event.target.value;
    // Validar que solo se permita números
    if (/^\d*$/.test(value)) {
      setOwnerDocumentId(value);
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getInit = () => {
    const BACKEND = "api/" + process.env.REACT_APP_BACKEND_VERSION;

    axios
      .getData(
        {},
        BACKEND + "/dashboard/documentos/doc_adiconales_usuario_" + data.id
      )
      .then((response) => {
        if (response && response.data) {
          setDocuments(response.data);
        }
      });
  };

  React.useEffect(() => {
    getInit();
  }, []);

  const handleChangeDocuments = (data) => {
    if (
      Object.entries(data) &&
      Object.entries(data)[0] &&
      Object.entries(data)[0][1]
    ) {
      setDocuments(Object.entries(data)[0][1]);
    }
    getInit();
  };

  const getCheck = () => {
    if (data && data.check_id) {
      console.log("CHECKID", data.check_id);
      const BACKEND = "api/" + process.env.REACT_APP_BACKEND_VERSION;

      axios
        .getData({}, BACKEND + "/background-check-by-checkId/" + data.check_id)
        .then((response) => {
          console.log("Response status:", response.status);
          console.log("Response headers:", response.headers);
          console.log("Data background check:", response);
          if (response) {
            setBackgroundCheckData(response);
          }
        })
        .catch((error) => {
          console.error("Error fetching background check data:", error);
          console.log(
            "Error status:",
            error.response ? error.response.status : "No response status"
          );
          console.log(
            "Error data:",
            error.response ? error.response.data : "No response data"
          );
        });
    } else {
      console.log("No check_id available");
    }
  };

  // Ejecutar getCheck solo cuando data.check_id cambie
  React.useEffect(() => {
    if (data && data.check_id) {
      getCheck();
      console.log("Ejecutando getCheck con check_id:", data.check_id);
    }
  }, [data?.check_id]);

  const handleConsultTruora = () => {
    console.log("Botón 'Consultar' clickeado");
    setIsLoading(true);

    if (data && data.documento_representante_legal) {
      console.log(
        "Documento para consultar",
        data.documento_representante_legal
      );

      const BACKEND = "api/" + process.env.REACT_APP_BACKEND_VERSION;

      axios
        .postData(
          {},
          BACKEND + "/background-check/" + data.documento_representante_legal
        )
        .then((response) => {
          console.log("Response status:", response.status);
          console.log("Data consultada background check:", response);
          if (response) {
            setBackgroundCheckData(response);
          }
        })
        .catch((error) => {
          console.error("Error fetching background check data:", error);
          console.log(
            "Error status:",
            error.response ? error.response.status : "No response status"
          );
          console.log(
            "Error data:",
            error.response ? error.response.data : "No response data"
          );
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      console.log("No documento available");
      setIsLoading(false);
    }
  };

  const getCheckPlate = () => {
    if (data && data.check_plate_id) {
      console.log("CHECKIDPLATE", data.check_plate_id);
      const BACKEND = "api/" + process.env.REACT_APP_BACKEND_VERSION;

      axios
        .getData(
          {},
          BACKEND + "/background-check-plate-by-checkId/" + data.check_plate_id
        )
        .then((response) => {
          console.log("Response status:", response.status);
          console.log("Response headers:", response.headers);
          console.log("Data background check plate:", response);
          if (response) {
            setBackgroundPlateCheckData(response);
          }
        })
        .catch((error) => {
          console.error("Error fetching background check data:", error);
          console.log(
            "Error status:",
            error.response ? error.response.status : "No response status"
          );
          console.log(
            "Error data:",
            error.response ? error.response.data : "No response data"
          );
        });
    } else {
      console.log("No check_plate_id available");
    }
  };

  // Ejecutar getCheckPlate solo cuando data.check_plate_id cambie
  React.useEffect(() => {
    if (data && data.check_plate_id) {
      getCheckPlate();
      console.log("Ejecutando getCheckPlate con check_plate_id:", data.check_plate_id);
    }
  }, [data?.check_plate_id]);

  const handleConsultTruoraPlate = () => {
    console.log("Botón 'Consultar placa' clickeado");
    setIsLoading(true);
    setShowAlert(false);

    if (!ownerDocumentId || !ownerDocumentType) {
      console.log("ownerDocumentId o ownerDocumentType no están llenos.");
      setShowAlert(true);
      setIsLoading(false);
      return;
    }

    if (data && data.vehiculo_placa) {
      const BACKEND = "api/" + process.env.REACT_APP_BACKEND_VERSION;
      axios
        .postData(
          {
            owner_document_type: ownerDocumentType,
            owner_document_id: ownerDocumentId,
          },
          BACKEND + "/background-check-plate/" + data.vehiculo_placa
        )
        .then((response) => {
          if (response) {
            setBackgroundPlateCheckData(response);
            window.location.reload();
          }
        })
        .catch((error) => {
          console.error("Error fetching background check data:", error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
    }
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          sx={{ background: "#f2f2f2" }}
        >
          <Tab label="Información básica" {...a11yProps(0)} />
          <Tab label="Datos Vehículo" {...a11yProps(1)} />
          <Tab label="Datos Bancarios" {...a11yProps(2)} />
          <Tab label="Documentos" {...a11yProps(3)} />
          <Tab label="Estatus del transportador" {...a11yProps(4)} />
          <Tab label="Información adicional" {...a11yProps(5)} />
          <Tab label="Información placa" {...a11yProps(6)} />
          <Tab label="Estado de cartera" {...a11yProps(7)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        {console.log("Datos", data)}
        <TransportadorBasic data={data} />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <TransportadorVehiculo data={data} />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <DocumentsBancarios
              id={"doc_adiconales_usuario_" + data.id + "_datos_bancarios"}
              base={"doc_adiconales_usuario_" + data.id}
              onUpload={handleChangeDocuments}
              placeholder="Ej: Certificación bancaria, estados de cuenta."
            />
          </Grid>
          <Grid item xs={12} md={8}>
            <Table3
              data={
                documents[
                  "doc_adiconales_usuario_" + data.id + "_datos_bancarios"
                ]
              }
            />
          </Grid>
        </Grid>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={3}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <Documents
              id={"doc_adiconales_usuario_" + data.id}
              base={"doc_adiconales_usuario_" + data.id}
              onUpload={handleChangeDocuments}
              placeholder="Ej: Pase de conducción, Pasaporte, etc. "
            />
          </Grid>
          <Grid item xs={12} md={8}>
            <Table2 data={documents["doc_adiconales_usuario_" + data.id]} />
          </Grid>
        </Grid>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={4}>
        <SelectStatus id={data.id} defaultValue={data.status} />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={5}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4} className="consult-field">
            <Button
              variant="contained"
              type="submit"
              onClick={handleConsultTruora}
            >
              Consultar
            </Button>
            {isLoading && <div className="loader">Consultando datos...</div>}
          </Grid>
          <Grid item xs={12} md={8}>
            <Table4 data={backgroundCheckData} />
          </Grid>
        </Grid>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={6}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <Grid container spacing={2} direction="column">
              <Grid item>
                <Select
                  value={ownerDocumentType}
                  onChange={(e) => setOwnerDocumentType(e.target.value)}
                  fullWidth
                  displayEmpty
                >
                  <MenuItem value="national_id">CC</MenuItem>
                </Select>
              </Grid>
              <Grid item>
                <TextField
                  value={ownerDocumentId}
                  onChange={handleOwnerDocumentIdChange}
                  fullWidth
                  placeholder="Ingrese el documento del propietario"
                />
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  type="submit"
                  onClick={handleConsultTruoraPlate}
                  fullWidth
                >
                  Consultar
                </Button>
                {isLoading && (
                  <div className="loader">Consultando datos...</div>
                )}
                {showAlert && (
                  <Alert severity="error">
                    Por favor, complete todos los campos antes de consultar.
                  </Alert>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={8}>
            <Table5 data={backgroundPlateCheckData} />
          </Grid>
        </Grid>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={7}>
        {console.log("Id transportador", data.id)}
        <TransportadorWallet data={data} />
      </CustomTabPanel>
    </Box>
  );
}

import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import PostAddIcon from "@mui/icons-material/PostAdd";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import useAxios from "../../hooks/useAxios";
import Input from "../../components/Input";
import useFormData from "../../hooks/useFormData";
import "./index.css";
import { Button, TextField } from "@mui/material"; // Asegúrate de importar Select y MenuItem si es de Material UI
import useAsyncStorage from "../../hooks/useAsyncStorage";
import Select from "../Select";
let file = false;

export default function DocumentUploader({
  onUpload,
  id,
  valor,
  fecha,
  base,
  placeholder,
}) {
  const { formData, onChange, setFormData } = useFormData();
  const axios = useAxios();
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [reset, setReset] = useState(false);
  const [loading, setLoading] = useState(false);
  const master = useAsyncStorage("master");

  // Usar useEffect para hacer log de master.data
  useEffect(() => {
    if (master.data) {
      console.log("Contenido de master.data:", master.data);
    } else {
      console.log("master.data aún no tiene datos");
    }
  }, [master.data]);

  const handleFileChange = (e) => {
    file = e.target.files[0];
    handleUpload(file);
    setSelectedFile(file);
  };

  const handleUpload = async (file) => {
    if (file) {
      try {
        setUploading(true);
        setSelectedFile(file);
      } catch (error) {
        console.error("Error al subir el documento:", error);
      } finally {
        setSelectedFile(null);
        setUploading(false);
      }
    }
  };

  const handleSubmit = (e) => {
    setLoading(true);
    e.preventDefault();

    // Asegúrate de incluir el valor de "doc_type" (tipo_documento_servicio) en los datos que envías al backend
    const dataToSend = {
      ...formData,
      doc: file,
      url: id,
      base: base,
      doc_type: formData.tipo_documento_servicio,
    };

    axios
      .postDataAttachment(
        dataToSend,
        "api/v1/dashboard/multimedia/uploads/documentos_usuarios"
      )
      .then((response) => {
        if (response && response.data && response.data.action) {
          setLoading(false);

          onUpload(response.data.docs);
          setReset(true);
          setUploading(false);
          setSelectedFile(null);
          setTimeout(() => {
            setReset(false);
          }, 1500);
        }
        return;
      })
      .catch((error) => {
        // Log para capturar y mostrar cualquier error que ocurra durante la subida
        console.error("Error al enviar los datos al backend:", error);
      });
  };

  return (
    <Grid component={"form"} className="container-file" onSubmit={handleSubmit}>
      {uploading && (
        <Grid align="center">Subiendo la información y documento</Grid>
      )}
      {reset && <Grid align="center">Depurando el sistema...</Grid>}
      {!reset && (
        <Grid align={"center"}>
          {selectedFile && (
            <Grid disabled={!selectedFile || uploading}>
              <Grid>
                <CloudUploadIcon sx={{ fontSize: 50, mt: 2 }} />
              </Grid>
              Archivo listo para subir...
            </Grid>
          )}
          <>
            <Grid className="container-file-upload cursor-pointer">
              {!selectedFile && (
                <>
                  <input
                    type="file"
                    accept=".pdf,.doc,.docx"
                    onChange={handleFileChange}
                    disabled={uploading}
                  />
                  <PostAddIcon sx={{ fontSize: 80, mt: 2 }} />
                </>
              )}
            </Grid>
            <Grid
              style={{
                opacity: !selectedFile ? 0.5 : 1, // Reducir la opacidad cuando está desactivado
                pointerEvents: !selectedFile ? "none" : "auto", // Deshabilitar interacciones cuando está desactivado
              }}
            >
              {valor && (
                <Input
                  sx={{ mb: 2, width: "100%" }}
                  required
                  onChange={onChange}
                  fullWidth
                  name="valor"
                  type="number"
                  label="Monto pago"
                />
              )}
              {fecha && (
                <TextField
                  sx={{ mb: 2, width: "100%" }}
                  required
                  onChange={onChange}
                  fullWidth
                  name="fecha"
                  type="date"
                  label="Fecha"
                  InputLabelProps={{
                    shrink: true, // Esto mantiene la etiqueta sobre el campo
                  }}
                />
              )}

              {/* Select condicional que solo aparece para "doc_servicio_" */}
              {id.startsWith("doc_servicio_") && (
                <Grid sx={{ mb: 2 }}>
                  <Select
                    data={formData} // Asegúrate de pasar formData para manejar los valores actuales
                    items={
                      master.data &&
                      master.data.tipo_documento_servicio &&
                      master.data.tipo_documento_servicio.length > 0
                        ? master.data.tipo_documento_servicio
                        : []
                    }
                    variant="standard"
                    sx={{ mb: { md: 1, xs: 0 }, width: "100%" }}
                    value={formData["tipo_documento_servicio"] || ""} // Pasar el valor actual del campo
                    onChange={(value) =>
                      setFormData({
                        ...formData,
                        tipo_documento_servicio: value,
                      })
                    } // Actualizar el estado
                    fullWidth
                    name="tipo_documento_servicio"
                    label="Tipo de documento"
                  />
                </Grid>
              )}

              <Input
                sx={{ mb: 2, width: "100%" }}
                required
                onChange={onChange}
                fullWidth
                name="name"
                type="text"
                label={
                  placeholder ||
                  "Nombre, Ej: Documento exportación, factura de venta."
                }
              />
              <Button disabled={loading} type="submit" variant="contained">
                Subir documento
              </Button>
            </Grid>
          </>
        </Grid>
      )}
    </Grid>
  );
}

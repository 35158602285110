import Container from "../../../components/Theme/Dashboard";
import Table from "../../../components/Table";
import View from "./View";

import usePermissions from "../../../hooks/usePermissions";

const td = [
  {
    label: "Empresa",
    field: "enterprise",
    className: "",
    align: "left",
  },
  {
    label: "Tipo de Vehículo",
    field: "type_vehicle",
    className: "",
    align: "left",
  },
  {
    label: "Origin",
    field: "origin",
    className: "",
    align: "left",
  },
  {
    label: "Destination",
    field: "destination",
    className: "",
    align: "left",
  },
  /*
  {
    label: "Valor Transporte",
    field: "transportation_value",
    className: "",
    align: "right",
  },
  {
    label: "Valor Cargue",
    field: "loading_value",
    className: "",
    align: "right",
  },
  {
    label: "Valor Descargue",
    field: "unloading_value",
    className: "",
    align: "right",
  },
  */
  {
    label: "Porcentaje aseguramiento",
    field: "insurance_percentage",
    className: "",
    align: "center",
  },
  {
    label: "Porcentaje mínimo comisión Global",
    field: "min_global_commission_percentage",
    className: "",
    align: "center",
  },
  /*
  {
    label: "Porcentaje máximo comisión Global",
    field: "max_global_commission_percentage",
    className: "",
    align: "center",
  },
  */
  {
    label: "Acción",
    field: "event",
    className: "",
    align: "center",
    event: [
      {
        label: "Ver",
        action: "view",
        component: View,
      },
    ],
  },
];

const Home = (props) => {
  const permissions = usePermissions("clientes", "index", {
    title: 'Configuración',
    filters: { enterpriseStatus: true },
    component: Table,
    td: td,
    fullScreenModal: true,
    download: true,
    create: View,
  });
  return <Container>{permissions.render()}</Container>;
};
export default Home;

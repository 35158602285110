import * as React from "react";
import {Button, Grid, Typography} from "@mui/material";
import Container from '../../Theme/Loading';
import {Star} from "@mui/icons-material";
import useAxios from "../../../hooks/useAxios";
import {formatCurrency} from "../../../helpers/functions";
import {useEffect} from "react";

const UserHomeServiciosDetalleServicio = ({data}) => {

    const axios = useAxios();
    const [transporters, setTransporters] = React.useState([]);
    const [selectedTransporter, setSelectedTransporter] = React.useState(null);
    const [selectedOfferId, setSelectedOfferId] = React.useState(null);

    const getInit = () => {
        axios.getData({}).then((response) => {
            if (response && response.data) {
                const BACKEND = "api/" + process.env.REACT_APP_BACKEND_VERSION;

                axios.getData({}, `${BACKEND}/offers?service_id=${response.data.id}`).then((response) => {
                    if (response && response.data) {
                        setTransporters(response.data);

                        // Buscar oferta seleccionada y asignarla al estado
                        const selectedOffer = response.data.find(transportador => transportador.selected_offer);
                        if (selectedOffer) {
                            setSelectedTransporter(selectedOffer);
                            setSelectedOfferId(selectedOffer.id);
                        }
                    }
                });
            }
        });
    };

    useEffect(() => {
        getInit();
    }, []);

    const handleSelectTransporter = () => {
        if (!selectedTransporter) {
            return;
        }

        const offerId = selectedTransporter.id;
        const BACKEND = "api/" + process.env.REACT_APP_BACKEND_VERSION;

        // Para actualizar el boton instantaneamente de Seleccione
        setSelectedOfferId(offerId);
        setTransporters((prevTransporters) =>
            prevTransporters.map((t) =>
                t.id === offerId
                    ? {...t, selected_offer: true}
                    : {...t, selected_offer: false}
            )
        );

        axios.postData({}, `${BACKEND}/offers/select/${offerId}`)
            .then((response) => {

                if (response?.status === 200) {
                    setSelectedOfferId(response.data.id);
                }
            })
            .catch((error) => {
                console.error("Error al seleccionar transportador:", error);
            });
    };


    const hasSelectedOffer = transporters.some(transportador => transportador.selected_offer);

    return (
        <Container>
            <Grid container spacing={2} sx={{mt: 1}}>

                {/* Transportador seleccionado (columna izquierda) */}
                <Grid item xs={12} md={6}>
                    {
                        selectedTransporter && (
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography variant="h6">Datos del transportador</Typography>
                                </Grid>
                                <Grid item xs={12} sx={{mt: 0}}>
                                    <div><b>Transportador:</b></div>
                                    <label>{selectedTransporter ? selectedTransporter.user.name : ""}</label>
                                </Grid>
                                <Grid item xs={12}>
                                    <div><b>Documento:</b></div>
                                    <label>{selectedTransporter?.user.documento_representante_legal || ""}</label>
                                </Grid>
                                <Grid item xs={12}>
                                    <div><b>Correo:</b></div>
                                    <label>{selectedTransporter?.user.email || ""}</label>
                                </Grid>
                                <Grid item xs={12}>
                                    <div><b>Teléfono:</b></div>
                                    <label>{selectedTransporter?.user.celular || ""}</label>
                                </Grid>
                                <Grid item xs={12} sx={{mt: 1}}>
                                    <Typography variant="h6">Datos del Vehículo</Typography>
                                </Grid>
                                <Grid item xs={12} sx={{mt: -2}}>
                                    <div><b>Marca:</b></div>
                                    <label>{selectedTransporter?.service?.transportador?.vehiculo_marca || ""}</label>
                                </Grid>
                                <Grid item xs={12}>
                                    <div><b>Modelo:</b></div>
                                    <label>{selectedTransporter?.service?.transportador?.vehiculo_modelo || ""}</label>
                                </Grid>
                                <Grid item xs={12}>
                                    <div><b>Placa:</b></div>
                                    <label>{selectedTransporter?.service?.transportador?.vehiculo_placa || ""}</label>
                                </Grid>
                                <Grid item xs={12}>
                                    <div><b>Tipo Vehículo:</b></div>
                                    <label>{selectedTransporter?.service?.vehiculo?.label || ""}</label>
                                </Grid>
                                <Grid item xs={12} sx={{mt: 1}}>
                                    <Typography variant="h6">Datos Oferta</Typography>
                                </Grid>
                                <Grid item xs={12} sx={{mt: -1}}>
                                    <div><b>Valor transporte</b></div>
                                    <label>{selectedTransporter ? formatCurrency(selectedTransporter.offer.data.transportation_value) : ""}</label>
                                </Grid>
                                <Grid item xs={12} sx={{}}>
                                    <div><b>Valor Global Trucker</b></div>
                                    <label>{selectedTransporter ? formatCurrency(selectedTransporter.offer.data.global_trucker_value) : ""}</label>
                                </Grid>
                                <Grid item xs={12} sx={{}}>
                                    <div><b>Valor Aseguramiento</b></div>
                                    <label>{selectedTransporter ? formatCurrency(selectedTransporter.offer.data.insurance_value) : ""}</label>
                                </Grid>
                                <Grid item xs={12}>
                                    <div><b>Valor total:</b></div>
                                    <label>{selectedTransporter ? formatCurrency(selectedTransporter.offer.data.total_value) : ""}</label>
                                </Grid>

                                {/* Botón para seleccionar transportador */}
                                {!selectedOfferId && (
                                    <Grid item xs={12} sx={{mb: 4}}>
                                        <Button
                                            variant="contained"
                                            fullWidth
                                            sx={{
                                                backgroundColor: "#e8501e",
                                                color: "#fff",
                                                borderRadius: "20px",
                                                "&:hover": {
                                                    backgroundColor: "#cf4219"
                                                },
                                                width: "250px",
                                            }}
                                            onClick={handleSelectTransporter}
                                        >
                                            Seleccionar Transportador
                                        </Button>
                                    </Grid>
                                )}

                            </Grid>
                        )
                    }
                </Grid>

                {/* Transportadores (columna derecha) */}
                <Grid item xs={12} md={6} sx={{borderLeft: "3px solid #c7ccd0", pl: 2}}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="h6">Transportadores</Typography>
                        </Grid>

                        {Array.isArray(transporters) && transporters.length > 0 ? (
                            transporters.map((transportador, index) => {
                                const {
                                    user: {id, name = "Sin nombre"} = {},
                                    offer: {offer_value = 0} = {},
                                    stats = 0,
                                    selected_offer = false
                                } = transportador;

                                return (
                                    <Grid
                                        key={id || index}
                                        item
                                        xs={12}
                                        container
                                        alignItems="center"
                                        spacing={2}
                                        sx={{py: 2, borderBottom: "1px solid #e0e0e0"}}
                                    >
                                        <Grid item xs="auto" sx={{mr: 2}}>
                                            <img
                                                src="https://placehold.co/60x60"
                                                alt="Avatar"
                                                loading="lazy"
                                                className="avatar-img-driver"
                                            />
                                        </Grid>

                                        <Grid item xs>
                                            <b>{name}:</b>
                                            <Typography variant="body2" color="text.secondary">
                                                Total: {formatCurrency(offer_value)}
                                            </Typography>

                                            <Grid container spacing={0.5}>
                                                {[...Array(5)].map((_, index) => (
                                                    <Star
                                                        key={index}
                                                        sx={{
                                                            color: index < stats ? "#FFD700" : "#d3d3d3"
                                                        }}
                                                    />
                                                ))}
                                            </Grid>
                                        </Grid>

                                        <Grid item xs="auto">
                                            {!hasSelectedOffer || selected_offer ? (
                                                <Button
                                                    variant="contained"
                                                    sx={{
                                                        backgroundColor: selected_offer ? "#34c759" : "#e8501e",
                                                        color: "#fff",
                                                        borderRadius: "20px",
                                                        "&:hover": {
                                                            backgroundColor: selected_offer ? "#34c759" : "#cf4219"
                                                        },
                                                    }}
                                                    onClick={!selected_offer ? () => setSelectedTransporter(transportador) : undefined}
                                                    //disabled={selected_offer}
                                                >
                                                    {selected_offer ? "Seleccionado" : "Seleccionar"}
                                                </Button>
                                            ) : null}
                                        </Grid>

                                    </Grid>
                                );
                            })
                        ) : (
                            <Grid item xs={12}>
                                <Typography variant="body1" align="center" sx={{py: 4}}>
                                    No hay transportadores disponibles.
                                </Typography>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    );
}

export default UserHomeServiciosDetalleServicio;